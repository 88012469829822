import React, { useEffect, useState } from 'react';

const importAll = (requireContext) => {
  return requireContext.keys().map((key) => ({
    filename: key.split('/').pop(), // Lấy tên file
    src: requireContext(key),  // Đường dẫn đã xử lý để load ảnh
  }));
};

const clothAutumn = importAll(require.context('./assets/images/autumn-winter', false, /\.(png|jpe?g|svg)$/));
const floral = importAll(require.context('./assets/images/floral', false, /\.(png|jpe?g|svg)$/));
const spaghetti = importAll(require.context('./assets/images/spaghetti_strap_top', false, /\.(png|jpe?g|svg)$/));
const tShirts = importAll(require.context('./assets/images/t_shirts', false, /\.(png|jpe?g|svg)$/));


const modelImages = importAll(require.context('./assets/model', false, /\.(png|jpe?g|svg)$/));


function App() {
  const allCloths = [...spaghetti, ...tShirts, ...clothAutumn, ...floral];
  const [modelImage, setModelImage] = useState(null);
  const [shirtImage, setShirtImage] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [apiUrl, setApiUrl] = useState(localStorage.getItem('apiUrl5') || 'https://e500-27-76-183-68.ngrok-free.app/upload_image');
  const [tempApiUrl, setTempApiUrl] = useState(apiUrl);

  const [shirtImages, setShirtImages] = useState(allCloths)

  const handleModelUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setModelImage({ src: imageUrl, filename: file.name });
    }
  };

  const handleShirtUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setShirtImage({ file, url: imageUrl });
    }
  };

  // Đếm số lần nhấp vào tiêu đề
  const handleTitleClick = () => {
    setClickCount(prevCount => prevCount + 1);
    if (clickCount + 1 === 5) {
      setShowModal(true);
      setClickCount(0); // Reset lại bộ đếm sau khi hiển thị modal
    }
  };

  const handleSaveUrl = () => {
    setApiUrl(tempApiUrl);
    localStorage.setItem('apiUrl5', tempApiUrl);
    setShowModal(false); // Đóng modal sau khi lưu URL
  };

  const handleCloseModal = () => {
    setShowModal(false); // Đóng modal mà không lưu thay đổi
  };
  // Chuyển đổi ảnh assets thành file
  const createFileFromImage = (src, filename) => {
    return fetch(src)
      .then((res) => res.blob())
      .then((blob) => {
        return new File([blob], filename, { type: blob.type });
      });
  };

  useEffect(() => {
    // Lấy query parameter từ URL bằng JavaScript native
    const urlParams = new URLSearchParams(window.location.search);
    const shirtId = urlParams.get('selected_shirt');
    if (shirtId) {
      // Nếu có giá trị trong query, thực hiện lấy áo với mã tương ứng

      let image = shirtImages.filter(item => item.filename.indexOf(shirtId) >= 0)
      console.log(image)
      if (image.length > 0) {
        setShirtImage({ src: image[0].src, filename: image[0].filename })
      }
    }
  }, []);

  const handleTryOn = async () => {
    if (modelImage && shirtImage) {
      setIsLoading(true);
      
      // Sử dụng FormData để gửi dữ liệu ảnh
      const formdata = new FormData();

      // Nếu người dùng upload ảnh từ máy tính, gửi file
      if (modelImage.file) {
        formdata.append('in_file', modelImage.file, modelImage.file.name);
      } else {
        // Nếu chọn ảnh từ assets, chuyển đổi ảnh thành file
        const file = await createFileFromImage(modelImage.src, modelImage.filename);
        formdata.append('in_file', file, file.name);
      }

      // Tương tự với ảnh áo
      formdata.append('cloth', shirtImage.filename);

      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
      };

      // Gọi API
      fetch(apiUrl, requestOptions)
        .then((response) => response.blob()) // Nhận ảnh dưới dạng blob
        .then((blob) => {
          const imageUrl = URL.createObjectURL(blob); // Chuyển đổi blob thành URL
          setResultImage(imageUrl); // Hiển thị ảnh trả về
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);



    console.log(`You selected: ${event.target.value}`);

    const vl = event.target.value;
    switch(vl) {
      case "all":
        setShirtImages(allCloths);
        break;
      case "spaghetti_strap_top":
        setShirtImages(spaghetti);
        break;
      case "t_shirts":
        setShirtImages(tShirts);
        break;
      case "autumn_winter_t_shirt":
        setShirtImages(clothAutumn);
        break;
      case "floral_t_shirt":
        setShirtImages(floral);
        break;
      default:
        break;
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      <h1 className="text-4xl font-bold mb-8 cursor-pointer" onClick={handleTitleClick}>
        [Blameo Tech] AI Virtual Try-On
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full max-w-5xl" style={{
          marginBottom: 50
        }}>
        {/* Cột 1: Ảnh người mẫu */}
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h2 className="text-lg font-semibold mb-4">Select a model</h2>
          <input type="file" accept="image/*" onChange={handleModelUpload} className="mb-4" />
          <br></br>
          <div className="flex space-x-4 overflow-x-scroll mb-4">
            {modelImages.map((img, index) => (
              <img
                key={index}
                src={img.src}
                alt={`Model ${index + 1}`}
                className="w-24 h-24 object-cover cursor-pointer"
                onClick={() => setModelImage({ src: img.src, filename: img.filename })}
              />
            ))}
          </div>
          <div className="w-full h-64 bg-gray-200 flex items-center justify-center rounded-lg">
            {modelImage ? (
              <img src={modelImage.src} alt="Model" className="max-h-full" />
            ) : (
              <span>Select a model</span>
            )}
          </div>
        </div>

        {/* Cột 2: Ảnh áo */}
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h2 className="text-lg font-semibold mb-4">Select a shirt</h2>
          {/* <input type="file" accept="image/*" onChange={handleShirtUpload} className="mb-4" /> */}
          <select
            id="shirtDropdown"
            value={selectedOption}
            onChange={handleSelectChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="all">All</option>
            <option value="spaghetti_strap_top">Spaghetti Strap Top</option>
            <option value="t_shirts">T-shirts</option>
            <option value="autumn_winter_t_shirt">Autumn-Winter T-shirt</option>
            <option value="floral_t_shirt">Floral T-shirt</option>
          </select>
          <br></br>
          <div className="flex space-x-4 overflow-x-scroll mb-4">
            {shirtImages.map((img, index) => (
              <img
                key={index}
                src={img.src}
                alt={`Shirt ${index + 1}`}
                className="w-24 h-24 object-cover cursor-pointer"
                onClick={() => setShirtImage({ src: img.src, filename: img.filename })}
              />
            ))}
          </div>
          <div className="w-full h-64 bg-gray-200 flex items-center justify-center rounded-lg">
            {shirtImage ? (
              <img src={shirtImage.src} alt="Shirt" className="max-h-full" />
            ) : (
              <span>Choose a shirt image</span>
            )}
          </div>
        </div>

        {/* Cột 3: Kết quả */}
        <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col">
          <button
            onClick={handleTryOn}
            className="mb-4 w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700"
            disabled={!modelImage || !shirtImage || isLoading}
          >
            {isLoading ? 'Processing...' : 'Try-on'}
          </button>
          <div className="w-full h-64 bg-gray-200 flex items-center justify-center rounded-lg">
            {isLoading ? (
              <div className="loader"></div>
            ) : resultImage ? (
              <img src={resultImage} alt="Result" className="max-h-full" />
            ) : (
              <span>No result</span>
            )}
          </div>
        </div>
        {/* Modal chỉnh sửa URL API */}
        {showModal && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl mb-4">Change the URL API</h2>
              <input
                type="text"
                value={tempApiUrl}
                onChange={(e) => setTempApiUrl(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg mb-4"
              />
              <div className="flex justify-end">
                <button onClick={handleCloseModal} className="px-4 py-2 bg-gray-300 rounded-lg mr-2">
                  Close
                </button>
                <button onClick={handleSaveUrl} className="px-4 py-2 bg-blue-500 text-white rounded-lg">
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={{
        textAlign: 'center',
        position: 'fixed',
        backgroundColor: '#f1f1f1',
        bottom: 0,
        width: '100%'
      }}>
        <h5>Copyright © 2024, Blameo AI. All rights reserved. - tung@blameo.com</h5>
      </div>
    </div>
  );
}

export default App;
